var homeTop = document.querySelector("#home").offsetTop;

// window.addEventListener("scroll", scrollListener);

function arrowClick() {
  window.scrollTo({ top: homeTop });
}

// Resetting object on resize
function configSlider() {
  homeTop = document.querySelector("#home").offsetTop;
}

window.addEventListener("resize", configSlider);
