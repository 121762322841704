var options = document.querySelectorAll(".product .options .option");

options.forEach((ele) => {
  ele.addEventListener("click", setPrize);
  ele.addEventListener("mouseover", onHoverOption);
  ele.addEventListener("mouseout", onMouseLeave);
});

function setPrize(event) {
  let newPrice = event.target.dataset.price;
  let targetPrice =
    event.target.parentElement.parentElement.previousElementSibling;
  let targetSelected = event.target.parentElement.previousElementSibling;
  let newWight = event.target.innerHTML;
  if (newPrice && targetPrice) {
    targetPrice.innerHTML = "₹ " + newPrice;
    targetSelected.innerHTML = newWight;
    targetSelected.dataset.price = newPrice;
  }
}

function onHoverOption(event) {
  let newPrice = event.target.dataset.price;
  let targetPrice =
    event.target.parentElement.parentElement.previousElementSibling;
  let targetSelected = event.target.parentElement.previousElementSibling;
  let newWight = event.target.innerHTML;
  if (newPrice && targetPrice) {
    targetPrice.innerHTML = "₹ " + newPrice;
    // targetSelected.innerHTML = newWight;
  }
}

function onMouseLeave(event) {
  let targetSelected = event.target.parentElement.previousElementSibling;
  event.target.parentElement.parentElement.previousElementSibling.innerHTML =
    "₹ " + targetSelected.dataset.price;
  // targetSelected.innerHTML = event.target.dataset.price;
}

// Get product's total width with padding
var products = document.querySelectorAll("#products .product").length;
var product = document.querySelector("#products .product");
var wrapper = document.querySelector("#products .wrapper");

function productsWidth() {
  let total = wrapper.offsetWidth;
  return total;
}

function setProductPageHeight() {
  let productPage = document.querySelector("#products");
  let remainingWidth = productsWidth() - window.innerWidth;
  let newHeight = "height:calc( 100vh + " + remainingWidth + "px );";
  productPage.style = newHeight;
}

setProductPageHeight();

// Reset objects when reesize

function configProduct() {
  options = document.querySelectorAll(".product .options .option");
  products = document.querySelectorAll("#products .product").length;
  product = document.querySelector("#products .product");
  wrapper = document.querySelector("#products .wrapper");
}

window.addEventListener("resize", configProduct);
