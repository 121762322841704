var nav = document.querySelector("nav");

var menuIcon = nav.querySelector(".menu-icon");
var one = menuIcon.querySelector(".one");
var two = menuIcon.querySelector(".two");
var three = menuIcon.querySelector(".three");

var menuOpenStatus = false;

function toggleMenuMobile(element) {
  // Close Menu
  if (menuOpenStatus) {
    nav.classList.remove("mobile-menu");
    menuOpenStatus = false;
  }
  // Open Menu
  else {
    nav.classList.add("mobile-menu");
    menuOpenStatus = true;
  }
}

// Resetting object on resize
function configMenuMobile() {
  nav = document.querySelector("nav");
  menuIcon = nav.querySelector(".menu-icon");
  one = menuIcon.querySelector(".one");
  two = menuIcon.querySelector(".two");
  three = menuIcon.querySelector(".three");
}

menuIcon.addEventListener("click", toggleMenuMobile);

// Change active page on scroll

var navTop = nav.offsetTop;
var navlistLinks = nav.querySelectorAll("ul li a");

var curruntY = 0;
var previousY = 0;

// Resetting object on resize

function configScrollLiser() {
  navTop = nav.offsetTop;
  navlistLinks = nav.querySelectorAll("ul li a");
}

function scrollListener() {
  previousY = curruntY;
  curruntY = window.pageYOffset || window.pageYOffset;
  //   console.log(curruntY);
  if (curruntY < navTop) {
    nav.style = "";
  } else {
    // nav.style = "position:fixed;top:0";
    // Show and hide navigation bar on scrolling

    //Moving down : Hide
    if (previousY < curruntY && navTop + 86 < curruntY) {
      nav.style = "position:fixed;top:-84px";
    }
    // Moving up : Show
    else {
      nav.style = "position:fixed;top:0";
    }
    if (menuOpenStatus) {
      nav.classList.remove("mobile-menu");
      menuOpenStatus = false;
    }
  }
  changeActiveMenu();
}

// starts animations for product

var productsTop = document.querySelector("#products").offsetTop;
var productsBottom = document.querySelector("#products").scrollHeight;

var productWrapper = document.querySelector("#products .wrapper");
var ease = 0.075;
var curruntAnimationY = 0;
var animationActiveStatus = false;
var startedScrollingProducts = false;
var animationActiveId;

var totalScrollingDistance = productWrapper.offsetWidth - window.innerWidth;

// Resetting object on resize
function configProductAnimation() {
  productsTop = document.querySelector("#products").offsetTop;
  productsBottom = document.querySelector("#products").scrollHeight;
  productWrapper = document.querySelector("#products .wrapper");
  totalScrollingDistance = productWrapper.offsetWidth - window.innerWidth;
}

function changeActiveMenu() {
  navlistLinks.forEach((link) => {
    let section = document.querySelector(link.dataset.href);
    let top = section.offsetTop;
    let bottom = top + section.scrollHeight;

    if (curruntY >= top && curruntY < bottom) {
      link.style = "color:orange;";
      if (
        link.dataset.href == "#products" &&
        bottom >= curruntY + window.innerHeight
      ) {
        startedScrollingProducts = true;
      }
    } else {
      link.style = "";
    }
    if (startedScrollingProducts == true) {
      startAnimation();
    }
  });
}

function startAnimation() {
  if (!animationActiveStatus) {
    curruntAnimationY = curruntY;
    animationActiveStatus = true;
    animationActiveId = requestAnimationFrame(updateAnimation);
  }
}

function stopAnimation() {
  animationActiveStatus = false;
  cancelAnimationFrame(animationActiveId);
}

function updateAnimation() {
  let diff = curruntY - curruntAnimationY;
  let delta = Math.abs(diff) < 0.1 ? 0 : diff * ease;

  if (delta) {
    curruntAnimationY += delta;
    curruntAnimationY = parseFloat(curruntAnimationY.toFixed(2));
    animationId = requestAnimationFrame(updateAnimation);
  } else {
    curruntAnimationY = curruntY;
    stopAnimation();
  }
  moveProductWrapper();
}

function moveProductWrapper() {
  let move = curruntAnimationY - productsTop;
  if (move >= totalScrollingDistance) {
    productWrapper.style = "left:" + -totalScrollingDistance + "px;";
    startedScrollingProducts = false;
    stopAnimation();
  } else if (move <= 0) {
    productWrapper.style = "left:" + -0 + "px;";
    startedScrollingProducts = false;
    stopAnimation();
  } else if (move < totalScrollingDistance && move > 0) {
    productWrapper.style = "left:" + -move + "px;";
  } else {
  }
}

window.addEventListener("scroll", scrollListener);

// ends animations for product

// Scroll to page on click link on nav

function scrollToPage(event) {
  let page = document.querySelector(event.target.dataset.href);
  window.scrollTo({
    top: page.offsetTop,
    behavior: "smooth",
  });
  // Close Menu
  if (menuOpenStatus) {
    nav.classList.remove("mobile-menu");
    menuOpenStatus = false;
  }
}

navlistLinks.forEach((ele) => {
  ele.addEventListener("click", scrollToPage);
});

// On Logo click

var logo = document.querySelector("nav .logo");

// Resetting object on resize
function configLogo() {
  logo = document.querySelector("nav .logo");
}

function onLogoClick() {
  let page = document.querySelector("#home");
  window.scrollTo({
    top: page.offsetTop,
    behavior: "smooth",
  });
  // Close Menu
  if (menuOpenStatus) {
    nav.classList.remove("mobile-menu");
    menuOpenStatus = false;
  }
}

logo.addEventListener("click", onLogoClick);

// Resetting object on resize main function
function configNav() {
  configMenuMobile();
  configScrollLiser();
  configProductAnimation();
  configLogo();
}

window.addEventListener("resize", configNav);
