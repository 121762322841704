// About Us
var playIcon = document.querySelector("#about .play-icon-container");

let model = document.querySelector("#contant-model");

myVideo = document.querySelector("#video");

function showModel() {
  model.classList.remove("about_us-video-contant-hide");
  model.classList.add("about_us-video-contant-show");

  //start video
  myVideo.play();
}

playIcon.addEventListener("click", showModel);

function hideModel() {
  model.classList.remove("about_us-video-contant-show");
  model.classList.add("about_us-video-contant-hide");

  //stop video
  myVideo.pause();
}

model.addEventListener("click", hideModel);

// Resetting object on resize
function configAbout() {
  playIcon = document.querySelector("#about .play-icon-container");
  model = document.querySelector("#contant-model");
}

window.addEventListener("resize", configAbout);
