// Plus icon event start
var plusIcon = document.querySelector("#contact .plus");

var plusIconRotation = 45;

function plusClick() {
  plusIconRotation += 45;
  plusIcon.style = "transform: rotate(" + plusIconRotation + "deg)";
  let overlay = document.querySelector("#contact .overlay");
  if (plusIconRotation % 90 == 0) {
    overlay.style = "top:" + -overlay.offsetHeight + "px";
  } else {
    overlay.style = "top:" + 0 + "px";
  }
}

plusIcon.addEventListener("click", plusClick);

// Plus icon event end

// Info menu start

var cellsCount;
var theta;
var radius;
var cellWidth;
var activeCell = 0;
var activeAngle = 0;
var cells = document.querySelectorAll(".info-cell");

function configInfoCells() {
  cells = document.querySelectorAll(".info-cell");

  cellsCount = cells.length;

  cellWidth = document.querySelector(".info-cell").scrollWidth;

  theta = 360 / cellsCount;

  radius = Math.round(cellWidth / Math.tan(theta / 2));

  styleCells();
}

function styleCells() {
  for (let index = 0; index < cells.length; index++) {
    let element = cells[index];

    element.style.transform =
      "rotateY(" + index * 90 + "deg) translateZ(" + radius * 2 + "px)";
  }
}

function rotateRight() {
  activeAngle += 90;

  for (let index = 0; index < cells.length; index++) {
    let element = cells[index];

    element.style.transform =
      "rotateY(" +
      (index * 90 + activeAngle) +
      "deg) translateZ(" +
      radius * 2 +
      "px)";
  }
}

function rotateLeft() {
  activeAngle -= 90;

  for (let index = 0; index < cells.length; index++) {
    let element = cells[index];

    element.style.transform =
      "rotateY(" +
      (index * 90 + activeAngle) +
      "deg) translateZ(" +
      radius +
      "px)";
  }
}

configInfoCells();

var activeIcon = 0;

var icons;

function configMenu() {
  icons = document.querySelectorAll(".menu-icons img");
  activeIcon = 0;
  icons.forEach((element) => {
    element.addEventListener("click", onIconClick);
  });
  styleIcons();
}

function styleIcons() {
  for (let index = 0; index < icons.length; index++) {
    const element = icons[index];
    if (index == getPreviousIcon()) {
      element.style = "left:0%;visibility:visible;cursor: pointer;";
    } else if (index == activeIcon) {
      element.style =
        "left:50%;width:56px;height:56px;border-width: 2px;visibility:visible;";
    } else if (index == getNextIcon()) {
      element.style = "left:100%;visibility:visible;cursor: pointer;";
    } else {
      element.style =
        "left:50%;visibility:hidden;width:0px;height:0px;border-color: transparent;";
    }
  }
}

function getPreviousIcon() {
  if (activeIcon == 0) {
    return icons.length - 1;
  } else {
    return parseInt(activeIcon) - 1;
  }
}

function getNextIcon() {
  if (activeIcon == icons.length - 1) {
    return 0;
  } else {
    return parseInt(activeIcon) + 1;
  }
}

function onIconClick(index) {
  let clickedIcon = index.target.dataset.no;

  if (parseInt(clickedIcon) == getPreviousIcon()) {
    activeIcon = clickedIcon;
    styleIcons();
    rotateRight();
  } else if (clickedIcon == getNextIcon()) {
    activeIcon = clickedIcon;
    styleIcons();
    rotateLeft();
  } else {
  }
}

configMenu();

// Info menu end

// Resetting object on resize

function configContact() {
  plusIcon = document.querySelector("#contact .plus");

  plusIconRotation = 45;
  configInfoCells();
  configMenu();
}

window.addEventListener("resize", configContact);
